<script lang="ts">
  import BlueEssence from "@images/blue-essence.svg";
  import Rocket from "@images/rocket_launch.svg";
  import HonorLevel from "@images/honor-level.svg";
  import CheckShield from "@images/check-shield.svg";
  import EmailIcon from "@images/mark_email_read.svg";
  import Repeat from "~icons/tabler/repeat";

  import { Image } from "@components/ux/image";
  import { Typography } from "@components/ux/typography";
  import {
    levelsDetails,
    type Level,
    accountsPerSubscription
  } from "@frontend/games/lol/account_info";
  import { cn } from "@helpers/tw-utilts";

  let className: string = "";

  export { className as class };

  export let level: Level = "30";
  export let isSubscription = false;
  export let variant: "large" | "small" = "large";

  let levelDetails = levelsDetails[level];

  export let itemClass = "";
  let itemBaseClass = "flex gap-2";
</script>

<div class={cn("grid gap-4", className)}>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={BlueEssence} alt="Blue Essence" class="h-6 w-6" />
    <Typography weight="medium" {variant}>
      {levelDetails?.estimated}
      <Typography weight="medium" {variant} as="span" color="muted">
        Blue Essence {levelDetails?.pure}
        pure - {levelDetails?.loot} loot (Estimated)
      </Typography>
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={HonorLevel} alt="Honor level" class="h-6 w-6" />
    <Typography weight="medium" {variant}>
      Level 2
      <Typography weight="medium" {variant} as="span" color="muted">
        Honor
      </Typography>
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckShield} alt="Lifetime Warranty" class="h-6 w-6" />
    <Typography weight="medium" {variant} color="muted" class="!text-green-500">
      Undetected & Lifetime Warranty
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={EmailIcon} alt="Email Access" class="h-6 w-6" />
    <Typography weight="medium" {variant} color="muted">
      Full email access
    </Typography>
  </div>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={Rocket} alt="Instant Delivery" class="h-6 w-6" />
    <Typography weight="medium" {variant} color="muted">
      Instant delivery
    </Typography>
  </div>
  {#if isSubscription}
    <div class={cn(itemBaseClass, itemClass)}>
      <Repeat class="h-8 w-8 text-slate-200" />
      <Typography weight="medium" {variant} color="muted">
        Up to
        <Typography weight="medium" {variant} as="span">
          {accountsPerSubscription}
        </Typography>
        Accounts (every
        <Typography weight="medium" {variant} as="span">
          {30 / accountsPerSubscription}
        </Typography>
        days)
      </Typography>
    </div>
  {/if}
</div>
